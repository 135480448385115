

import gsap from "gsap";
import { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import React, { useEffect, useRef, useState } from "react";
import Header from "./header";
import vehicle from "../services/JSON/vehicle.json";
import vehicleCategory from "../services/JSON/vehicleCategory.json";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import Loader from "./Loader/loader";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const Homepage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Initial check
  const nav = useNavigate();
  const component = useRef();
  const items = useRef();

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 6000);

    // Event listener to update isMobile state on window resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(loadingTimeout);
      window.removeEventListener('resize', handleResize);
      sessionStorage.setItem("loaderCheck", "1");
    };
  }, []);

  return (
    <>
      {isLoading && sessionStorage.getItem("loaderCheck") !== "1" && window.innerWidth >= 768 ? (
        <Loader />
      ) : (
        <div ref={component}>
          <Header />
          <div className="main">
            <div className="inner-wrap mainwrap">
              <section className="hero-section scroll section-1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="hero-content-blk">
                        <div className="hero-inner-blk">
                          <h1>The Elite Exotic & Luxury Car Experience</h1>
                          <div className="txt-blk">
                            <p>Serving All of The United States</p>
                          </div>
                        </div>
                        <div className="effect">
                          <h2 className="splittext">SCROLL TO EXPLORE</h2>
                        </div>
                        <div className="scroll-down">
                          <span className="rectline" />
                          <span className="rectblk" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg">
                  
                    <video loop autoPlay muted playsInline>
                      <source src="videos/supercar.mp4" type="video/mp4" />
                    </video>           
                </div>
              </section>

              <section className="carbrands">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="carbrandlist">
                        <div className="common-section-heading" style={{ textAlign: 'center' }}>
                          <h2 style={{ fontWeight: 'bold' }}>We Have Worked With The Biggest Brands</h2>
                        </div>
                        <br />
                        <ul>
                          <li>
                            <img
                              src="https://images.squarespace-cdn.com/content/6481060bbf96c5798f680cf4/91e6b3e7-b22a-45a8-94e6-214122da9cdf/b+logo+black+green.png?format=1500w&content-type=image%2Fpng"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="https://seeklogo.com/images/E/ed-hardy-logo-7851F63725-seeklogo.com.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="https://seeklogo.com/images/E/ecko-unltd-logo-BFB25DFA8A-seeklogo.com.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img src="https://images.squarespace-cdn.com/content/614b36a3b8032b674e0b0005/552819e7-e454-442d-bee9-c8126698cdde/wyclef_logo_white_transparent.png?format=1500w&content-type=image%2Fpng" alt="Brands Logo" />
                          </li>
                          <li>
                            <img
                              src="https://mma.prnewswire.com/media/1999075/NYFWLOGO_HORIZONTAL_black_Logo.jpg?p=facebook"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="https://logovectorseek.com/wp-content/uploads/2021/11/opening-ceremony-logo-vector.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="https://cdn1.weddingwire.com/assets/img/corporative/weddingwire-generic-share.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="https://i.pinimg.com/originals/8e/74/01/8e74014ee37e87289442cc7279090e17.png"
                              alt="Brands Logo"
                            />
                          </li>
                        </ul>
                        <div className="common-section-heading" style={{ textAlign: 'center' }}>
                          <br />
                          <h2 style={{ fontWeight: 'bold' }}>& Many More.</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>


              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <section className="common-card-section">
                  <span className="position-text" style={{ fontSize: '60px' }}> ED HARDY X BROADWAY SUPERCARS </span>
                  <div className="container">
                    <div className="row mb-3 mb-md-5">
                      <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                        <div className="common-section-heading">
                          <h2 style={{ textAlign: 'center', fontWeight: 'bold' }}>Unbeaten. Unmatched. Unforgettable.</h2>
                        </div>
                        <br />
                        <div>
                          <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: 500 }}>
                            Welcome to Broadway Supercars, where we believe that luxury should be accessible to all and every drive should be an unforgettable experience. Our mission is simple: to curate unique and exceptional journeys for our customers, weaving moments of splendor and thrill into the fabric of everyday life. With our diverse fleet of exotic and luxury vehicles, we invite you to indulge in the allure of automotive excellence without compromising on affordability.
                          </p>
                          <p style={{ textAlign: 'center', fontSize: '18px', fontWeight: 500 }}>
                            Whether you're seeking a weekend escape, a special occasion celebration, or simply craving the exhilaration of a supercar ride, we're here to make your dreams a reality. At Broadway Supercars, we're not just in the business of exotic cars; we're in the business of creating memories that last a lifetime. Join us on the road to extraordinary adventures—where luxury meets accessibility, and every drive is a masterpiece in motion.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 d-flex justify-content-center align-items-center">
                        {!isMobile && (
                          <video src="/videos/Reel.mp4" width="320" height="240" autoPlay muted loop className="reel" />
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <section className="carbrands">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="carbrandlist">
                        <ul>
                          <li>
                            <img
                              src="images/lamborghini-logo.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="images/corvette-logo.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="images/aston-martin-logo.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img src="images/audi-logo.png" alt="Brands Logo" />
                          </li>
                          <li>
                            <img
                              src="images/rolls-royce-logo.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="images/ferrari-logo.png"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="images/bentley-logo.jpg"
                              alt="Brands Logo"
                            />
                          </li>
                          <li>
                            <img
                              src="images/mclaren-logo.png"
                              alt="Brands Logo"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="scroll section-2">
                <div className="feature-blk">
                  <div className="feature-inner-blk">
                    <div className="feature-card">
                      {/* <div className="hero-inner-blk">
                        <h1>Are You Ready to Drive The Car of Your Dreams?</h1>
                        <div className="txt-blk">
                          <h1>We're Ready to Make It Happen.</h1>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Homepage;
